export const BASE_POSTS_PATH = "/posts";
export const BASE_MEDIA_PATH = "/media";
export const DATA_FILE = "posts.json";

export const MOBILE_BREAKPOINT = 700;

export const APP_URL = "https://blokken.dk";
export const APP_TITLE = "Blokken";
export const APP_DESCRIPTION = "Blokken skildrer mit helt almindelige ikke-influencer liv i 20’erne";
export const APP_COVER_PATH = "/cover.png";
export const APP_KEYWORDS = ["livsstil", "michigan", "udveksling", "cbs", "oplevelser", "rejse", "udvekslingsblog", "livsstilsblog", "usa"];

