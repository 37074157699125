import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "../styles/shared";

@customElement("mb-navbar")
class MbNavbar extends LitElement  {
    static get styles() {
        return [sharedStyles, css`
            :host {
                display: block;
                width: 100%;
            }
        `];
    }

    render() {
        return html`
            <nav></nav>
        `;
    }
}
