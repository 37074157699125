import {css, html, LitElement, PropertyValues} from "lit";
import { customElement } from "lit/decorators/custom-element.js";
import { sharedStyles } from "./styles/shared";
import {
    addRouteChangeListener,
    dispatchRouteChangeEvent, ensureEndSlash,
    goTo,
    ROUTES,
    setDefaultSEOMetadata,
} from "./router";
import "./components/footer";
import "./components/navbar";

setDefaultSEOMetadata();

@customElement("mb-app")
class MbApp extends LitElement  {
    static get styles() {
        return [sharedStyles, css`
            :host {
                display: flex;
                flex-direction: column;
            }
            
            #page {
                flex-grow: 1;
                display: block;
                padding:  var(--space-xl) 0;
                min-height: 100vh;
            }
            
        `];
    }

    protected firstUpdated(props: PropertyValues) {
        super.firstUpdated(props);

        ensureEndSlash();
        window.addEventListener("popstate", dispatchRouteChangeEvent);
        addRouteChangeListener(() => {
            ensureEndSlash();
            this.requestUpdate();
        });
    }

    renderPage () {
        if (location.pathname.startsWith(ROUTES.post(""))) {
            const slug = location.pathname.split(`/`)?.[2]?.trim();
            if (slug?.length) {
                import("./pages/blog-post-page");
                return html`<mb-blog-post-page slug="${slug}"></my-blog-post-page>`;
            }
        }

        goTo(ROUTES.home, {replace: true, silent: true});

        import("./pages/home-page");
        return html`<mb-home-page></mb-home-page>`;
    }

    render() {
        return html`
            <mb-navbar></mb-navbar>
            <div id="page">
                ${this.renderPage()}
            </div>
            <mb-footer></mb-footer>
        `;
    }
}
