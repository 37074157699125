import {APP_COVER_PATH, APP_DESCRIPTION, APP_KEYWORDS, APP_TITLE, APP_URL} from "./config";

export const ROUTES = {
    home: "/",
    post: (id: string) => `/post/${id.length ? `${id}/` : ""}`,
}

const ROUTE_CHANGE_EVENT = "route-change";

export function goTo(route: string, {replace, silent, data}:{replace?: boolean, silent?: boolean, data?: any} = {}) {
    if (replace) {
        history.replaceState(data ?? {}, "", route);
    } else {
        history.pushState(data ?? {}, "", route);
    }

    if (!silent) {
        dispatchRouteChangeEvent();
    }
}

export function dispatchRouteChangeEvent() {
    window.dispatchEvent(new CustomEvent(ROUTE_CHANGE_EVENT));
}

export function addRouteChangeListener(listener: () => void) {
    window.addEventListener(ROUTE_CHANGE_EVENT, listener);
}

export function isRouteActive(route: string, {exact}: {exact?: boolean} = {}) {
    if (exact) {
        return location.pathname === route;
    }

    return location.pathname.startsWith(route);
}

export function setSEOMetadata ({title, description, keywords, image}: { title: string, description: string; keywords?: string[], image?: string }) {
    document.title = title;
    document.querySelector("meta[name='title']")?.setAttribute("content", title);
    document.querySelector("meta[property='og:title']")?.setAttribute("content", title);
    document.querySelector("meta[name='description']")?.setAttribute("content", description);
    document.querySelector("meta[property='og:description']")?.setAttribute("content", description);
    document.querySelector("meta[name='keywords']")?.setAttribute("content", keywords?.join(", ") ?? "");
    document.querySelector("meta[property='og:image']")?.setAttribute("content", `${APP_URL}${image ?? ""}`);
}

export function ensureEndSlash () {
    if (!location.pathname.endsWith("/")) {
        goTo(`${location.pathname}/`, {replace: true, silent: true});
    }
}

export function setDefaultSEOMetadata () {
    setSEOMetadata({
        title: APP_TITLE,
        description: APP_DESCRIPTION,
        image: APP_COVER_PATH,
        keywords: APP_KEYWORDS
    });
}
